import { defaultPostHeaderForJsonData } from '../../../api/front';
import { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function requestExhibitorMeeting(
    requestManager: IEventRequestManager,
    eventId: string,
    exhibitorId: string,
    kind: string,
    title: string,
    description: string,
    request: any,
    exhibitorAccountId: string
) {
    return requestManager.postEventJson(`meeting/exhibitor`, JSON.stringify({
        title,
        description,
        exhibitorId,
        kind,
        exhibitorAccountId,
        request: request ? JSON.stringify(request) : null
    }), defaultPostHeaderForJsonData);
}

export function acceptMeetingInvitation(eventRequests: IEventRequests, threadMessageId: string, text: string,
    meetingId: string, exhibitorAccountId: string) {
    const url = `meeting/${meetingId}/invitation/accept`;
    return eventRequests.apiFront.postEventJson(url, JSON.stringify({
        discussionMessageId: threadMessageId,
        text: text,
        meetingId: meetingId,
        exhibitorAccountId: exhibitorAccountId
    }), defaultPostHeaderForJsonData);
}

export function declineMeetingInvitation(eventRequests: IEventRequests, threadMessageId, text, meetingId: string) {
    const url = `meeting/${meetingId}/invitation/decline`;
    return eventRequests.apiFront.postEventJson(url, JSON.stringify({
        discussionMessageId: threadMessageId,
        text: text,
        meetingId: meetingId
    }), defaultPostHeaderForJsonData);
}

export function requestExhibitorBusinessMeeting(
    requestManager: IEventRequestManager,
    eventId: string,
    exhibitorId: string,
    kind: string,
    title: string,
    description: string,
    request: any,
    exhibitorAccountId: string,
    startDate,
    endDate,
    isOnline: boolean,
    roomId: string
) {
    return requestManager.postJson<any>(`${eventId}/meeting/exhibitor`, JSON.stringify({
        title,
        description,
        exhibitorId,
        kind,
        exhibitorAccountId,
        startDate,
        endDate,
        isOnline,
        roomId,
        request: request ? JSON.stringify(request) : null
    }), defaultPostHeaderForJsonData);
}

export function acceptMeetingRequest(eventRequests: IEventRequests, meetingId: string, payload: any): Promise<any> {
    const url = `meeting/${meetingId}/accept`;
    return eventRequests.apiFront.postEventJson(url, JSON.stringify(payload), defaultPostHeaderForJsonData);
}

export function declineMeetingRequest(eventRequests: IEventRequests, meetingId: string, reason: string): Promise<any> {
    const url = `meeting/${meetingId}/decline`;
    return eventRequests.apiFront.postEventJson(url, JSON.stringify({ reason: reason }), defaultPostHeaderForJsonData);
}

export function cancelMeeting(eventRequests: IEventRequests, meetingId: string, reason: string, forceCancel?: boolean) {
    const url = `meeting/${meetingId}/cancel`;
    const payload = { reason: reason, forceCancel: forceCancel };
    return eventRequests.apiFront.postEventJson(url, JSON.stringify(payload), defaultPostHeaderForJsonData);
}

export function getAvailableDates(eventRequests: IEventRequests, targetPersonId: string, capacity?) {
    const url = `meeting/available-timeslots?capacity=${capacity || ''}&targetpersonid=${targetPersonId}`;
    return eventRequests.apiFront.getEventJson(url);
}

export function meetingVideoChatAccess(eventRequests: IEventRequests, meetingId: string): Promise<any> {
    return eventRequests.apiFront.postEventJson<any>(`meeting/${meetingId}/videochat`);
}

export function meetingVideoChatAccessv2(eventRequests: IEventRequests, meetingId: string): Promise<any> {
    return eventRequests.apiFront.postEventJson<any>(`meeting/${meetingId}/videochatv2`);
}

