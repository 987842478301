import type { ActionQueueMessage } from '@inwink/actionqueue';
import type { Entities } from '@inwink/entities/entities';
import type { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';
import * as Discussions from '../../data/discussions';
import {
    declineMeetingRequest as _declineMeetingRequest,
    acceptMeetingRequest as _acceptMeetingRequest
} from '../../api/meetings';
import { sendMeetingFeedback } from '../../api/meetingfeedback';

export const handlers: any = {};

interface ISendMeetingFeedbackMessage {
    userId: string;
    eventId: string;
    feedback: Entities.IMeetingFeedback;
    patch: Partial<Entities.IMeetingFeedback>;
}

export function queueMeetingFeedbackMessage(
    dispatch,
    getState: () => States.IAppState,
    feedback: Entities.IMeetingFeedback,
    patch: Partial<Entities.IMeetingFeedback>
): Promise<ActionQueueMessage<ISendMeetingFeedbackMessage>> {
    const state = getState();
    const user = state.user.currentUser;
    const payload: ISendMeetingFeedbackMessage = {
        userId: user.detail.id,
        eventId: state.event.eventid,
        feedback: feedback,
        patch: patch
    };

    const existing = user.data.meetingfeedbacks.data
        .filter((mf) => mf.meetingId === feedback.meetingId && mf.meetingPersonId === feedback.meetingPersonId)[0];
    if (!existing) {
        user.data.meetingfeedbacks.insert(feedback);
    } else {
        const update = { ...feedback,
            $loki: (existing as any).$loki,
            meta: (existing as any).meta, };
        user.data.meetingfeedbacks.update(update);
    }

    user.data.save().then(null, (err) => {
        console.error(err);
    });

    return Promise.resolve({
        type: "sendmeetingfeedback",
        correlationid: "sendmeetingfeedback-" + feedback.id,
        payload: payload
    });
}

handlers.sendmeetingfeedback = (
    message: ActionQueueMessage<ISendMeetingFeedbackMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return sendMeetingFeedback(requestmanager, message.payload.feedback.meetingId, message.payload.patch).then((res) => {
            if ((message.payload.feedback as any).tempId) {
                const state = getState();
                const user = state.user.currentUser;
                const existing = user.data.meetingfeedbacks.data
                    .filter((mf) => mf.id === (message.payload.feedback as any).tempId)[0];
                if (existing) {
                    user.data.meetingfeedbacks.remove(existing);
                }
                user.data.meetingfeedbacks.insert(res);
            }

            context.refreshUserData = true;
        });
    });
};

interface IAcceptMeetingRequestMessage {
    userId: string;
    eventId: string;
    threadId: string;
    messageId: string;
    requestedMeetingId: string;
    startDate: string | Date;
    endDate: string | Date;
    capacity: number;
    text: string;
    timeSlotNotAvailableText: string;
    location: string;
    meetingKind: string;
    exhibitorAccountId: string;
}

export function acceptMeetingRequest(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    messageId: string,
    requestedMeetingId: string,
    text: string,
    timeSlotNotAvailableText: string,
    startdate: string | Date,
    enddate: string | Date,
    capacity: number,
    isOnline: boolean,
    location: string,
    meetingKind: string,
    exhibitorAccountId: string,
    roomId: string
): Promise<ActionQueueMessage<IAcceptMeetingRequestMessage>> {
    const state = getState();
    const payload = <IAcceptMeetingRequestMessage>{
        userId: state.user.currentUser.detail.id,
        eventId: state.event.eventid,
        threadId: discussionThreadId,
        messageId: messageId,
        requestedMeetingId: requestedMeetingId,
        startDate: startdate,
        endDate: enddate,
        capacity: capacity,
        isOnline: isOnline,
        text: text,
        timeSlotNotAvailableText: timeSlotNotAvailableText,
        location: location,
        meetingKind: meetingKind,
        exhibitorAccountId: exhibitorAccountId,
        roomId: roomId
    };

    if (requestedMeetingId) {
        const meeting = state.user.currentUser.data.meetings.data.filter((m) => m.id === requestedMeetingId)[0];
        if (meeting) {
            meeting.status = "Accepted";
            const me = meeting.meetingPersons.filter((mp) => mp.person.id === state.user.currentUser.detail.id)[0];
            if (me) {
                me.status = "Accepted";
            }
            state.user.currentUser.data.meetings.update(meeting);
            state.user.currentUser.data.save();
        }
    }

    if (payload.threadId) {
        return Discussions.getDiscussionMessages(payload.userId, payload.eventId, payload.threadId).then((discussion) => {
            const discussionMessage = discussion.messages.data.find((m) => m.id === payload.messageId);
            if (discussionMessage && discussionMessage.extendedFields) {
                const extendedMessage = JSON.parse(discussionMessage.extendedFields);
                extendedMessage.payload.status = 1;
                discussionMessage.extendedFields = JSON.stringify(extendedMessage);
                discussion.messages.update(discussionMessage);
                return discussion.save();
            }
        }).then(() => {
            return {
                type: "meeting-acceptmeetingrequest",
                payload: payload
            };
        });
    }
    return Promise.resolve({
        type: "meeting-acceptmeetingrequest",
        payload: payload
    });
}

handlers["meeting-acceptmeetingrequest"] = (
    message: ActionQueueMessage<IAcceptMeetingRequestMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return _acceptMeetingRequest(
            requestmanager,
            message.payload.requestedMeetingId,
            message.payload
        ).then(() => {
            context.syncUser = true;
            context.syncMeetings = true;
        });
    });
};

// ------------------------ Refus d'une demande de RDV

interface IDeclineMeetingRequestMessage {
    userId: string;
    eventId: string;
    threadId: string;
    messageId: string;
    requestedMeetingId: string;
    reason: string
}

export function declineMeetingRequest(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    messageId: string,
    requestedMeetingId: string,
    reason: string
): Promise<ActionQueueMessage<IDeclineMeetingRequestMessage>> {
    const state = getState();
    const payload = <IDeclineMeetingRequestMessage>{
        userId: state.user.currentUser.detail.id,
        eventId: state.event.eventid,
        requestedMeetingId: requestedMeetingId,
        threadId: discussionThreadId,
        messageId: messageId,
        reason: reason
    };

    if (requestedMeetingId) {
        const meeting = state.user.currentUser.data.meetings.data.filter((m) => m.id === requestedMeetingId)[0];
        if (meeting) {
            meeting.status = "Declined";
            const me = meeting.meetingPersons.filter((mp) => mp.person.id === state.user.currentUser.detail.id)[0];
            if (me) {
                me.status = "Declined";
            }
            state.user.currentUser.data.meetings.update(meeting);
            state.user.currentUser.data.save();
        }
    }

    if (payload.threadId) {
        return Discussions.getDiscussionMessages(payload.userId, payload.eventId, payload.threadId).then((discussion) => {
            const discussionMessage = discussion.messages.data.find((m) => m.id === payload.messageId);
            if (discussionMessage && discussionMessage.extendedFields) {
                const extendedMessage = JSON.parse(discussionMessage.extendedFields);
                extendedMessage.payload.status = 1;
                discussionMessage.extendedFields = JSON.stringify(extendedMessage);
                discussion.messages.update(discussionMessage);
                return discussion.save();
            }
        }).then(() => {
            return {
                type: "meeting-declinemeetingrequest",
                payload: payload
            };
        });
    }
    return Promise.resolve({
        type: "meeting-declinemeetingrequest",
        payload: payload
    });
}

handlers["meeting-declinemeetingrequest"] = (
    message: ActionQueueMessage<IDeclineMeetingRequestMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        // return MeetingsAPI.declineMeetingRequest(
        //     requestmanager,
        //     message.payload.requestedMeetingId,
        //     message.payload.reason).then(() => {
        //         context.syncUser = true;
        //     });
        return _declineMeetingRequest(
            requestmanager,
            message.payload.requestedMeetingId,
            message.payload.reason
        ).then(() => {
            context.syncUser = true;
        });
    });
};
